// VARIABLES
@import "../../../styles/variables";

// STYLES
.container {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}
.wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1.2rem;

  @include breakpoint(md) {
    padding: 1.5rem 1.5rem 1.7rem;
  }
}

.REACTSPRING {
  background-color: lightblue;
  padding: 5px;
}
