// VARIABLES
@import "../../../styles/variables";

// STYLES
.container {
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  overflow: hidden;
  width: 40%;
  height: 0;
  padding-bottom: 50%;
  border-radius: 1rem;
  background: $gray-light;
  cursor: pointer;

  & + .container {
    margin-left: 1rem;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px -6rem 3rem -3rem rgba(0, 0, 0, 0.7);
  }
}

.image {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heading {
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0.7rem 1rem;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.1;
  color: $white;
  word-break: break-word;

  small {
    display: block;
    margin-bottom: 0.1rem;
    font-size: 1rem;
    font-weight: normal;
  }
}

.button {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  color: transparent;
}
