// VARIABLES
@import "../../../styles/variables";

// STYLES
.answers {
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 0 1rem 1.2rem;

  @include breakpoint(md) {
    padding: 0 1.5rem 1.7rem;
  }
}
