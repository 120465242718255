// VARIABLES
@import "../../../styles/variables";

// STYLING
.app {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: $white;

  @include breakpoint(md) {
    width: 37.5rem;
    max-width: 100%;
    height: 64rem;
    max-height: calc(100% - 4rem);
    border-radius: 1rem;
  }
}

.layer {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}
