// VARIABLES
@import "../../../styles/variables";

// STYLES
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $white;

  @include breakpoint(md) {
    border-radius: 1rem;
  }
}

$spinnerSize: 40;
.spinner {
  width: $spinnerSize + px;
  height: $spinnerSize + px;

  circle {
    fill: transparent;
    stroke: $purple;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-dasharray: (3.14 * $spinnerSize);
    transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
    animation: spinner 2s linear infinite;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: (0.66 * $spinnerSize);
  }
  50% {
    transform: rotate(720deg);
    stroke-dashoffset: (3.14 * $spinnerSize);
  }
  100% {
    transform: rotate(1080deg);
    stroke-dashoffset: (0.66 * $spinnerSize);
  }
}
