// VARIABLES
@import "../../../styles/variables";

// STYLES
.horizontal {
  overflow: hidden;

  > .wrapper {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    padding-bottom: 30px;
    margin-bottom: -30px;
  }
}
.vertical {
  overflow: hidden;
  width: 100%;
  height: 100%;

  > .wrapper {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    width: calc(100% + 30px);
    height: 100%;
    padding-right: 30px;
  }
}
