// VARIABLES
@import "../../../styles/variables";

// STYLES
.header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 5.2rem;
  padding: 1.2rem 1.1rem 1.1rem;
  border-bottom: 0.1rem solid $gray;
  background-color: $white;
}
